import * as React from 'react'
import styled from 'theme/styled-components'

import EditoItem from './EditoItem'
import ContentItem from 'webview/ContentItem'

import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import EmptyLabel from 'components/label/EmptyLabel'
import SectionsList, { Section } from 'components/list/SectionsList'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as EditoStore from './store'
import * as SiteStore from 'site/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const TABS_FILTERS = ['practicallife', 'service', 'mobility']

const EditosScreen = () => {
  const i18n = useI18n()

  const infos = useReducer(EditoStore.store, (s) => s.infos)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const FILTERS = TABS_FILTERS.filter(
    (f) => f !== 'mobility' || !site?.functionalities.some((fun) => fun.type === 'INTER_CAMPUS' && fun.activated) // filter mobility if present in InterCampus
  )

  const SECTIONS: Section[] = FILTERS.map((filter) => ({
    label: i18n.t(`screens.practicalinformation.filter.${filter}`),
    id: filter,
  }))

  const [selectedSection, setSelectedSection] = React.useState<Section>(SECTIONS[0])
  const [loading, setLoading] = React.useState(false)

  const displayedInfos = React.useMemo(() => infos.filter((n) => n.folder === selectedSection.id), [
    infos,
    selectedSection,
  ])

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens[selectedSection.id === 'mobility' ? 'move' : 'infos'],
      screen_name: values.screens[selectedSection.id as PracticalInformationFolder],
    })
  }, [selectedSection])

  React.useEffect(() => {
    setLoading(true)

    api
      .all(i18n.lang, site.id, 'PRACTICAL_INFORMATION')
      .then((l) => EditoStore.actions.setInfos(l.practicalInformations ? l.practicalInformations : []))
      .catch(EditoStore.actions.reset)
      .finally(() => setLoading(false))
  }, [site])

  if (loading) {
    return <Loader />
  }

  const renderItem = (item: PracticalInformationSimple) => {
    // Ici, item peut être une page éditoriale interne ou un lien vers une webview
    if (item.type === 'CONTENT') {
      return <EditoItem key={item.id} edito={item} isImageIcon />
    }
    return (
      <ContentItem
        key={item.id}
        content={item}
        feature={item.folder === 'mobility' ? 'move' : 'infos'}
        folder={item.folder as PracticalInformationFolder}
        isImageIcon
      />
    )
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.practicalinformation.title')} />
        <Header>
          <SectionsList sections={SECTIONS} selectedSection={selectedSection} onSectionClick={setSelectedSection} />
        </Header>

        {displayedInfos.length === 0 ? (
          <EmptyLabel label={i18n.t('screens.practicalinformation.empty')} icon="info_alt" />
        ) : (
          <Animator isList gap={30}>
            {displayedInfos.map(renderItem)}
          </Animator>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default EditosScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 172px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Header = styled('div')`
  align-items: center;
`
