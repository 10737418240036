import * as React from 'react'

import useI18n from 'i18n/useI18n'

import useTheme from 'theme/useTheme'

import api from 'practicalinformation/api'

import ListItemLink from 'components/list/ListItemLink'

import ListItemRework from 'components/list/ListItemRework'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  content: PracticalInformationSimple
  feature: 'news' | 'infos' | 'safeAtWork' | 'move'
  folder: PracticalInformationFolder | SafeAtWorkFolder
  isImageIcon?: boolean
  reworkItem?: boolean
}

const ListItem = ({ content, feature, folder, isImageIcon, reworkItem }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [contentDetails, setContentDetails] = React.useState<PracticalInformationDetails>()

  React.useEffect(() => {
    api.get(i18n.lang, content.id, content.type).then(setContentDetails)
  }, [content])

  if (!contentDetails) {
    return null
  }

  const onClickWebView = () =>
    analytics.screen({
      screen_feature: values.screens[feature],
      screen_name: values.screens[folder],
      screen_object_id: contentDetails.id,
    })

  if (!!reworkItem) {
    return (
      <ListItemRework
        key={content.id}
        link={contentDetails.link || contentDetails.pdf || ''}
        imageUrl={content.photo}
        title={content.title}
        description={content.description}
        themeColor={theme.colors.primaryText}
        onClick={onClickWebView}
        externalLink
      />
    )
  }

  return (
    <ListItemLink
      imageSource={content.photo || require('core/src/assets/content_default.jpeg').default}
      link={contentDetails.link || contentDetails.pdf}
      title={content.title}
      description={content.description}
      isImageIcon={isImageIcon}
      onClick={onClickWebView}
      key={content.id}
    />
  )
}

export default ListItem
