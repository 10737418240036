import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { breakpoints } from 'utils/breakpoints'

import Main, { HEADER_HEIGHT } from 'components/main/Main'
import MultipleChoices from 'components/survey/MultipleChoices'
import Notation from 'components/survey/SurveyNotation'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'
import Icon from 'components/icons/Icon'
import IconLabel from 'components/label/IconLabel'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import * as SurveyStore from './store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'
import { isAfter } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'
import { areValidAnswers, filterSurveys } from './utils'

const MIN_NOTE_COMMENT = 3
const MAX_CHOICES = 3
const MAX_LENGTH = 250

interface Props {
  id: string
}

export const MAX_WIDTH = 800

const SurveyDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [survey, setSurvey] = React.useState<SurveyWS>()
  const [isChallengeQuiz, setIsChallengeQuiz] = React.useState(false)

  React.useEffect(() => {
    if (!!survey) {
      analytics.screen({
        screen_feature: values.screens.surveys,
        screen_name: values.screens[survey.category === 'QUIZ' ? 'quizDetail' : 'surveyDetail'],
        screen_object_id: id,
      })
    }
  }, [survey])

  React.useEffect(() => {
    if (!!site && !survey) {
      setStatus('loading')
      api
        .surveysList(site.id)
        .then((res) => {
          setSurvey(res.surveys.find((s) => s.id === id))
          SurveyStore.actions.setSurveys(filterSurveys(res.surveys))
          setStatus('ok')
        })
        .catch(() => setStatus('error'))

      // On vérifie si l'utilisateur est dans l'équipe d'un challenge de pas en cours
      api.getAllMyTeams().then(({ teams }) => setIsChallengeQuiz(teams.length > 0))
    }
  }, [id, site, i18n.lang])

  if (!survey || !site) {
    return (
      <Main>
        <ScreenContainer>
          <TitleHelmet title={i18n.t('screens.surveyDetail.title')} />

          <Tree
            urlEnd="survey"
            previousPageTitle="screens.survey.title"
            currentPageTitle={i18n.t('screens.surveyDetail.title')}
          />

          {status === 'loading' ? <Loader /> : <Error>{i18n.t('common.errorDescription')}</Error>}
        </ScreenContainer>
      </Main>
    )
  }

  // Si la date limite du sondage est atteinte
  if (isAfter(new Date(), new Date(survey.endDate))) {
    return (
      <Main>
        <ScreenContainer>
          <TitleHelmet title={`${i18n.t('screens.surveyDetail.titleDetailPage')}${survey.title}`} />

          <Tree urlEnd="survey" previousPageTitle="screens.survey.title" currentPageTitle={survey.title} />

          {status === 'loading' ? <Loader /> : <Error>{i18n.t('screens.surveyDetail.endDatePassed')}</Error>}
        </ScreenContainer>
      </Main>
    )
  }

  return <SurveyDetail survey={survey} site={site} isChallengeQuiz={isChallengeQuiz} />
}

interface SurveyProps {
  survey: SurveyWS
  site: SiteV4
  isChallengeQuiz: boolean
}

const SurveyDetail = ({ survey, site, isChallengeQuiz }: SurveyProps) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const questions = survey.questions.sort((a, b) => a.position - b.position)

  const isQuiz = React.useMemo(() => !!survey && survey.category === 'QUIZ', [survey])

  const initialAnswers = React.useMemo(
    () =>
      questions.reduce((acc, cur) => {
        acc.push({ questionId: cur.id, type: cur.type, choices: cur.type === 'CHOICE' ? [] : undefined })
        return acc
      }, [] as SurveyAnswerForm[]),
    [questions]
  )

  const [answers, setAnswers] = React.useState<SurveyAnswerForm[]>(initialAnswers)
  const [isAnswering, setIsAnswering] = React.useState(false)
  const [isAnswered, setIsAnswered] = React.useState(false)
  const [comment, setComment] = React.useState('')
  const [checkRules, setCheckRules] = React.useState(false)

  const isCommentEmpty = React.useMemo(
    () => answers.some((answer) => !!answer.note && answer.note <= MIN_NOTE_COMMENT) && comment.length === 0,
    [comment, answers]
  )

  const emptyFields = React.useMemo(
    () =>
      questions.filter((question) => {
        const findAnswer = answers.find((answer) => answer.questionId === question.id)
        if (!!findAnswer && !!findAnswer.choices && findAnswer.choices.length === 0) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'NOTE' && !findAnswer.note) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'TEXT' && !findAnswer.text) {
          return true
        }
        return false
      }),
    [questions, answers]
  )

  const quizValidAnswers = React.useMemo(
    () =>
      questions
        .filter((question) => question.type === 'CHOICE')
        .map((question) => ({
          id: question.id,
          validAnswers:
            question.choices
              ?.filter((choice) => !!choice.isRightAnswer)
              .map((choice) => choice.id)
              .sort((a, b) => a.localeCompare(b)) || [],
        })),
    [questions]
  )

  const quizValidQuestions = React.useMemo(
    () =>
      quizValidAnswers.map((question) => {
        const userAnswers = answers.find((q) => q.questionId === question.id)?.choices || []

        return {
          id: question.id,
          isValid: areValidAnswers(question.validAnswers, userAnswers),
        }
      }),
    [quizValidAnswers, answers]
  )

  const quizScore = React.useMemo(() => quizValidQuestions.filter((q) => q.isValid).length, [quizValidQuestions])

  const submit = () => {
    setCheckRules(true)

    if (!isCommentEmpty && emptyFields.length === 0) {
      analytics.event({
        event_feature: values.eventName.survey,
        event_action: values.actions[isQuiz ? 'submitQuiz' : 'submitSurvey'],
        event_object_id: survey.id,
      })

      setIsAnswering(true)

      const body: SurveyAnswersWS = {
        surveyAnswers: answers,
        comment: comment,
      }

      api
        .answerSurvey(site.id, survey.id, body)
        .then(() => {
          // Message de succès par défaut
          Alert.open({
            title: survey.successText || i18n.t(`screens.surveyDetail.send${isQuiz ? 'Quiz' : 'Survey'}Succeed`),
            titleIcon: 'check_circle',
            iconColor: Theme.colors.valid,
            buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
          })

          if (isQuiz) {
            // Quiz : on reste sur la page de détail pour afficher les réponses du quiz
            setIsAnswered(true)
          } else {
            // Sondage : on est redirigé vers la liste des sondages ou la page d'accueil
            api
              .surveysList(site.id)
              .then(({ surveys }) => {
                if (surveys.length > 0) {
                  navigation.push('/survey')
                } else {
                  navigation.push('/')
                }
              })
              .catch(() => navigation.push('/'))
          }
        })
        .catch(() =>
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t('screens.surveyDetail.submitError'),
            buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
          })
        )
        .finally(() => setIsAnswering(false))
    } else {
      Alert.open({
        title: i18n.t('common.warning'),
        description: i18n.t('errors.form.emptyFields'),
        buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
      })
    }
  }

  const onChange = (answer: SurveyAnswerForm) => {
    setAnswers([...answers.filter((ans) => ans.questionId !== answer.questionId), answer])
  }

  const renderItem = (item: QuestionWS, index: number) => {
    const isEmptyField = emptyFields.some((field) => field.id === item.id)

    return (
      <QuestionContainer key={`question_${item.id}`}>
        <QuestionIndex>{i18n.t('screens.surveyDetail.questionIndex', { index: index + 1 })}</QuestionIndex>
        <QuestionTitle>{item.title}</QuestionTitle>
        {!!item.description && <QuestionDescription dangerouslySetInnerHTML={{ __html: item.description }} />}
        {renderQuestion(item)}
        {checkRules && isEmptyField && <Error>{i18n.t('screens.surveyDetail.requiredField')}</Error>}
        {isAnswered && !!item.quizAdditionalExplanation && (
          <ExplanationContainer>
            <Icon name="question_round" size={24} color={Theme.colors.primaryDark} />
            <ExplanationContent>
              <ExplanationTitle>{i18n.t('screens.surveyDetail.quiz.explanation')}</ExplanationTitle>
              <ExplanationLabel dangerouslySetInnerHTML={{ __html: item.quizAdditionalExplanation }} />
            </ExplanationContent>
          </ExplanationContainer>
        )}
        <Separator />
      </QuestionContainer>
    )
  }

  const renderQuestion = (question: QuestionWS) => {
    const findAnswer = answers.find((a) => a.questionId === question.id)
    if (!findAnswer) {
      return null
    }
    switch (question.type) {
      case 'CHOICE':
        return (
          <MultipleChoices
            choices={question.choices || []}
            multipleChoices={question.multiple}
            onChange={(choices) => onChange({ questionId: question.id, choices, type: question.type })}
            editable={!isAnswering}
            isAnswered={isAnswered}
            maxChoices={MAX_CHOICES}
            selectedIDs={findAnswer.choices || []}
          />
        )
      case 'NOTE':
        return (
          <Notation
            onChange={(note) => onChange({ questionId: question.id, note, type: question.type })}
            editable={!isAnswering}
            note={findAnswer.note}
          />
        )
      case 'TEXT':
        return (
          <QuestionInput
            onChange={(e) => onChange({ questionId: question.id, text: e.target.value, type: question.type })}
            disabled={isAnswering}
            maxLength={MAX_LENGTH}
            placeholder={i18n.t('screens.surveyDetail.commentPlaceholder', { max: MAX_LENGTH })}
          />
        )
      default:
        return null
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t(`screens.survey.tags.${survey.category || 'SURVEY'}`)} : ${survey.title}`} />

        <Tree urlEnd="survey" previousPageTitle="screens.survey.title" currentPageTitle={survey.title} />

        <ContentContainer>
          <HeaderContainer>
            <Title>{survey.title}</Title>
            <DateContainer>
              <IconLabel
                label={i18n.t('screens.survey.endDate', { date: new Date(survey.endDate) })}
                fontSize={15}
                margin={8}
                icon="agenda_filled"
                iconColor={Theme.colors.iconicGrey}
              />
            </DateContainer>
            {!!survey.description && <SurveyDescription dangerouslySetInnerHTML={{ __html: survey.description }} />}
          </HeaderContainer>

          <QuestionsList>{questions.map(renderItem)}</QuestionsList>

          {!isQuiz && (
            <CommentContainer>
              <QuestionIndex>{i18n.t('screens.surveyDetail.commentDescription')}</QuestionIndex>
              <QuestionInput
                isMarginTop
                onChange={(e) => setComment(e.target.value)}
                disabled={isAnswering}
                maxLength={MAX_LENGTH}
                placeholder={i18n.t('screens.surveyDetail.commentPlaceholder', { max: MAX_LENGTH })}
              />
              {checkRules && isCommentEmpty && <Error>{i18n.t('screens.surveyDetail.requiredComment')}</Error>}
            </CommentContainer>
          )}

          {isAnswered ? (
            <>
              <ScoreContainer>
                <ScoreLabel>{i18n.t('screens.surveyDetail.quiz.resultTitle')}</ScoreLabel>
                <ScoreValue>{`${quizScore}/${quizValidQuestions.length}`}</ScoreValue>
                <ScoreLabel>{i18n.t('screens.surveyDetail.quiz.resultScore', { count: quizScore })}</ScoreLabel>
                {isChallengeQuiz &&
                  !!survey.linkedToChallenges &&
                  survey.challengePointsCoef !== null &&
                  survey.challengePointsCoef !== undefined && (
                    <ScoreLabel>
                      {i18n.t(`screens.surveyDetail.quiz.challenge${quizScore > 0 ? '' : 'NoPoints'}`, {
                        count: quizScore * survey.challengePointsCoef,
                      })}
                    </ScoreLabel>
                  )}
              </ScoreContainer>

              <ButtonContainer margin={32}>
                <Button label={i18n.t('screens.surveyDetail.quiz.exit')} onClick={() => navigation.push('/survey')} />
              </ButtonContainer>
            </>
          ) : (
            <ButtonContainer margin={survey.category === 'SURVEY' ? 46 : 0}>
              <Button
                disabled={isAnswering}
                label={i18n.t('common.validate')}
                onClick={submit}
                isLoading={isAnswering}
              />
            </ButtonContainer>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default SurveyDetailScreen

// MAIN SCREEN

const ScreenContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT + 80}px); // 80px de padding vertical
  padding: 40px 76px;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 40px 35px;
  }
`

const ContentContainer = styled('div')`
  display: flex;
  align-self: center;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 40px 100px 20px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 40px 50px 20px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 40px 0px 20px;
  }
`

const Error = styled('p')`
  padding-top: 10px;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.error};
`

// HEADER

const HeaderContainer = styled('div')`
  max-width: ${MAX_WIDTH}px;
  margin-bottom: 45px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background: ${(props) => props.theme.colors.background};
`

const Title = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primary};
`

const DateContainer = styled('div')`
  margin-top: 17px;
`

const SurveyDescription = styled('div')`
  ${(props) => props.theme.fonts.body};
  line-height: 24px;
  margin-top: 24px;
`

// QUESTIONS LIST

const Separator = styled('div')`
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  margin: 37px 0px;
`

const QuestionsList = styled('ul')`
  list-style: none;
  max-width: ${MAX_WIDTH}px;
  margin: 0 20px;
  padding: 0;
`

const QuestionContainer = styled('li')``

// QUESTION HEADER

const QuestionIndex = styled('p')`
  ${(props) => props.theme.fonts.body};
`

const QuestionTitle = styled('p')<{ noMargin?: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  margin: ${(props) => (props.noMargin ? 0 : '16px 0 32px')};
`

const QuestionDescription = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.iconicGrey};
  line-height: 24px;
  margin-bottom: 16px;
`

const QuestionInput = styled('textarea')<{ isMarginTop?: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
  background-color: ${(props) => props.theme.colors.contentBackground};
  outline: 0px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  min-height: 130px;
  padding: 10px;
  ${(props) => props.isMarginTop && 'margin-top: 32px'};
  width: calc(100% - 22px); // 2*10px padding + 2*1px border
`

const CommentContainer = styled('div')`
  margin: 0 20px;
`

// QUIZ

const ExplanationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border-radius: 12px;
  margin-top: 32px;
  padding: 16px 20px;
`

const ExplanationContent = styled('div')`
  flex-direction: column;
  margin-left: 16px;
`

const ExplanationTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 2px 0 8px;
`

const ExplanationLabel = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const ButtonContainer = styled('div')<{ margin: number }>`
  margin: ${(props) => props.margin}px 20px 20px;
  align-items: flex-start;
`

// SCORE

const ScoreContainer = styled('div')`
  background-color: rgba(0, 150, 64, 0.1);
  border-radius: 12px;
  margin: 0 20px;
  padding: 20px;
`

const ScoreValue = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 16px 0 8px;
`

const ScoreLabel = styled('p')`
  ${(props) => props.theme.fonts.body};
`
