import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  tags: string[]
  selected: string[]
  onSubmit: (selected: string[]) => void
  onCancel: () => void
}

const TagFilter = ({ tags, selected, onSubmit, onCancel }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const [current, setCurrent] = React.useState(selected || tags)

  const allTagSelected = current.length === tags.length

  const toggleItem = (item: string) => {
    const active = current.includes(item)

    if (active) {
      setCurrent(current.filter((c) => c !== item))
    } else {
      setCurrent([...current, item])
    }
  }

  const save = () => {
    analytics.event({ event_feature: values.eventName.news, event_action: values.actions.filter })
    onSubmit(current)
  }

  const renderItem = (item: string) => {
    const active = current.includes(item)

    return (
      <Item key={item}>
        <CheckButton
          onClick={() => toggleItem(item)}
          aria-label={i18n.t(`accessibility.ariaLabels.news.filterButton${active ? 'Activated' : ''}`, {
            filter: i18n.t(`tags.${item}`),
          })}>
          <CheckText>{i18n.t(`tags.${item}`)}</CheckText>
          <CheckBox active={active}>
            {active && <Icon name="check" color={Theme.colors.white} size={12} cursor="pointer" />}
          </CheckBox>
        </CheckButton>
      </Item>
    )
  }

  return (
    <Container id="TagFilter" aria-labelledby="modalFilterSelectionHeading" role="dialog" aria-modal="true">
      <IconContainer onClick={onCancel}>
        <Icon name="cross" size={24} color={Theme.colors.iconicGrey} cursor="pointer" />
      </IconContainer>

      <Title id="modalFilterSelectionHeading">{i18n.t('screens.news.tag.filter')}</Title>

      <RowContainer>
        <Description>{i18n.t('screens.news.tag.selectFilters')}</Description>
        <CliquableContainer onClick={() => setCurrent(allTagSelected ? [] : tags)}>
          <Description color={Theme.colors.functionalities.news}>
            {i18n.t(`screens.news.tag.${allTagSelected ? 'unselectAll' : 'selectAll'}`)}
          </Description>
        </CliquableContainer>
      </RowContainer>

      <TagList>{tags.map(renderItem)}</TagList>

      <ButtonContainer>
        <Button label={i18n.t('screens.news.tag.apply')} onClick={save} />
      </ButtonContainer>
    </Container>
  )
}

export default TagFilter

const Container = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
`

const IconContainer = styled('button')`
  background-color: ${(props) => props.theme.colors.background};
  align-self: flex-end;
  margin: 0px;
  padding: 0px;
  border: 0px;
  cursor: pointer;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 40px;
`

const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`

const CliquableContainer = styled('div')`
  color: ${(props) => props.theme.colors.functionalities.news};
  cursor: pointer;
`

const Description = styled('p')<{ color?: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color || props.theme.colors.primaryDark};
`

const Item = styled('li')`
  display: flex;
  flex: 1;
`

const CheckButton = styled('button')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  margin: 8px 0px;
  padding: 0px;
  border: 0px;
`

const CheckBox = styled('div')<{ active?: boolean }>`
  height: 16px;
  width: 16px;
  border: 1px solid ${(props) => props.theme.colors.primaryDark};
  background-color: ${(props) => (props.active ? props.theme.colors.primaryDark : 'transparent')};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`

const CheckText = styled('p')`
  ${(props) => props.theme.fonts.body};
`

const ButtonContainer = styled('div')`
  margin-top: 24px;
  align-items: center;
`

const TagList = styled('ul')`
  list-style: none;
  padding: 0 12px 0 0;
  max-height: 325px;
  overflow: auto;
`
