const drawer: string[] = [
  'safeatwork',
  'map',
  'booking',
  'catering',
  'guest',
  'restaurant',
  'practicalinformation',
  'phonebook',
  'actu',
  'transport',
  'intercampus',
  'animation',
  'survey',
]

const mapping: { [key in ScreenName]: string } = {
  safeAtWork: 'SAFE_AT_WORK',
  safeAtWorkDetail: 'NONE',
  transport: 'TRANSPORT',
  intercampus: 'INTER_CAMPUS',
  mobility: 'MOBILITY',
  move: 'MOVE',
  booking: 'BOOKING',
  meeting: 'MEETING',
  catering: 'CATERING',
  cateringDetail: 'NONE',
  cgu: 'NONE',
  consultCgu: 'NONE',
  practicalinformation: 'PRACTICAL_INFORMATION',
  editoDetail: 'NONE',
  home: 'HOME',
  login: 'NONE',
  map: 'MAP',
  news: 'ACTU',
  newsDetail: 'NONE',
  restaurant: 'RESTAURANT',
  restaurantDetail: 'NONE',
  site: 'NONE',
  phonebook: 'PHONEBOOK',
  guest: 'GUEST',
  editVisit: 'EDITVISIT',
  accessibility: 'ACCESSIBILITY',
  survey: 'SURVEY',
  surveyDetail: 'NONE',
  register: 'ANIMATION',
  registerDetail: 'NONE',
}

export { drawer, mapping }
