import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import api from './api'

import { Link } from 'react-router-dom'
import { isBefore, format, parseISO } from 'date-fns'

import Loader from 'components/status/Loader'
import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import ModalLoader from 'components/status/ModalLoader'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  shouldUpdateMyVisits: boolean
}

const MyVisits = ({ shouldUpdateMyVisits }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const [myVisits, setMyVisits] = React.useState<Visit[]>([])
  const [loading, setLoading] = React.useState(false)
  const [apiLoading, setApiLoading] = React.useState(false)

  React.useEffect(() => retrieveMyVisits(), [])

  React.useEffect(() => {
    if (apiLoading) {
      ModalLoader.open()
    } else {
      ModalLoader.close()
    }
  }, [apiLoading])

  React.useEffect(() => retrieveMyVisits(), [shouldUpdateMyVisits])

  const retrieveMyVisits = () => {
    setLoading(true)
    api
      .getListVisits()
      .then((res) => setMyVisits(sortMyVisits(res.visits)))
      .catch(() => setMyVisits([]))
      .finally(() => setLoading(false))
  }

  const toStringDateFormat = (date: string) =>
    format(parseISO(date), "EEEE dd MMMM H'h'mm", { locale: i18n.locales[i18n.lang] })

  const sortMyVisits = (listVisitsToSort: Visit[]) =>
    listVisitsToSort.sort((a, b) => (isBefore(parseISO(a.startDateTime), parseISO(b.startDateTime)) ? -1 : 1))

  const deleteVisit = (visit: Visit) => {
    // Mise à jour des API types : id peut être undefined
    if (!!visit.id) {
      const visitId = visit.id
      Alert.open({
        title: i18n.t('common.warning'),
        description: i18n.t('screens.guest.alert.alertDeleteVisitDesc', { meetingName: visit.meetingName }),
        buttons: [
          {
            label: i18n.t('common.yes'),
            onClick: () => {
              Alert.close()
              setApiLoading(true)
              api
                .deleteVisit(visitId)
                .then(() => {
                  analytics.event({
                    event_feature: values.eventName.guest,
                    event_action: values.actions.deleteVisit,
                    event_object_id: visitId.toString(),
                  })

                  Alert.open({
                    title: i18n.t('common.information'),
                    description: i18n.t('screens.guest.alert.visitDeleted'),
                  })
                  retrieveMyVisits()
                })
                .catch(() =>
                  Alert.open({
                    title: i18n.t('common.error'),
                    description: i18n.t('screens.guest.alert.errorDeletingVisit'),
                  })
                )
                .finally(() => {
                  setApiLoading(false)
                })
            },
          },
          {
            label: i18n.t('common.cancel'),
            onClick: Alert.close,
          },
        ],
      })
    }
  }

  const renderVisit = (visit: Visit, index: number, list: Visit[]) => {
    const isLast = list.length === index + 1
    return (
      <ListItem key={visit.id}>
        <VisitTitle>
          {visit.meetingName === '' ? i18n.t('screens.guest.meetingNameNotSpecified') : visit.meetingName}
        </VisitTitle>
        <VisitInfo>
          {
            i18n
              .t('screens.guest.dateVisit', {
                startDateTime: toStringDateFormat(visit.startDateTime),
                endDateTime: toStringDateFormat(visit.endDateTime),
              })
              .trim()
              .replace(/^\w/, (c) => c.toUpperCase())
            // Capitalize the first char of the string
            // Date-fns doesn't capitalize week days in french
          }
        </VisitInfo>
        <VisitInfo>
          {i18n.t('screens.guest.displayCompanion', {
            fullName: visit.isAccompanying ? i18n.t('screens.guest.companion.myself') : visit.companionName,
          })}
        </VisitInfo>
        <VisitInfo>{i18n.t('screens.guest.visitors.visitorLowerCaseCount', { count: visit.visitorNumber })}</VisitInfo>

        {visit.updatable && !!visit.id ? (
          <Actions>
            <ActionLink key={visit.id} to={`/editVisit/${visit.id}`}>
              <Icon name="pencil" size={17} color={Theme.colors.secondary} cursor="pointer" />
              <ActionText>{i18n.t('common.edit')}</ActionText>
            </ActionLink>
            <ActionButton onClick={() => deleteVisit(visit)}>
              <Icon name="trash" size={17} color={Theme.colors.secondary} cursor="pointer" />
              <ActionText>{i18n.t('common.delete')}</ActionText>
            </ActionButton>
          </Actions>
        ) : (
          <CantEditVisit>{i18n.t('screens.guest.cantEditVisit')}</CantEditVisit>
        )}
        {!isLast && <Separator />}
      </ListItem>
    )
  }

  return (
    <>
      <DrawerTitle>{i18n.t('screens.guest.drawerTitle')}</DrawerTitle>
      {loading ? (
        <Loader />
      ) : myVisits.length > 0 ? (
        <ListContainer>{myVisits.map(renderVisit)}</ListContainer>
      ) : (
        <MessageText>{i18n.t('screens.guest.noVisits')}</MessageText>
      )}
    </>
  )
}

export default MyVisits

// CONTAINERS

const Actions = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding: 5px 0px;
`

// LISTES

const ListContainer = styled('ol')`
  overflow-y: scroll;
  list-style: none;
  padding: 0px 20px 10px;
`

const ListItem = styled('li')`
  padding: 5px 0px;
`

// TEXTES

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 10px 22px;
  color: ${(props) => props.theme.colors.primaryText};
`

const VisitTitle = styled('h2')`
  ${(props) => props.theme.fonts.tagTiroir};
  display: flex;
  margin: 0px 0px 5px;
  color: ${(props) => props.theme.colors.primaryText};
`

const VisitInfo = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  color: ${(props) => props.theme.colors.primaryText};
`

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 0px 22px;
  padding: 10px 0px;
  color: ${(props) => props.theme.colors.primaryText};
`

const ActionText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  padding-left: 12px;
  margin: 0px;
`

const CantEditVisit = styled('p')`
  flex: 1;
  justify-content: center;
  ${(props) => props.theme.fonts.label};
  padding: 10px 24px;
  margin: 0px;
`

// BUTTONS & LINKS

const ActionButton = styled('button')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 15px;
  border: 0px;
  background: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

const ActionLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 15px;
  background: ${(props) => props.theme.colors.background};
  text-decoration: none;
`

// AUTRES

const Separator = styled('div')`
  background-color: ${(props) => props.theme.colors.disable};
  height: 1px;
`
