import * as React from 'react'
import styled from 'theme/styled-components'

import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import * as SiteStore from './store'
import * as UserStore from 'store/user/user'
import useReducer from 'store/useReducer'

import { breakpoints } from 'utils/breakpoints'

import api from './api'

import useI18n from 'i18n/useI18n'
import useNavigation from 'core/src/layout/useNavigation'

import { extractSite } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const LOGO_SIZE = 90
const IMG_HEIGHT = 207
const IMG_WIDTH = 276
const HORIZONTAL_GAP = 36
const SITE_OUTER_WIDTH = IMG_WIDTH + 2 * HORIZONTAL_GAP

const SitePicker = () => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const sites = useReducer(SiteStore.store, (s) => s.sites)
  const user = useReducer(UserStore.store, (s) => s.user!)

  const [loading, setLoading] = React.useState(true)
  const defaultSiteCode = React.useMemo(() => extractSite(user), [user])

  React.useEffect(() => {
    api
      .all(i18n.lang)
      .then((res) => SiteStore.actions.setSites(res.sites || []))
      .catch(() => SiteStore.actions.resetSites())
      .finally(() => setLoading(false))
  }, [])

  const onValidate = (site: SiteV4) => {
    analytics.event({
      event_feature: values.eventName.changeSite,
      event_action: values.actions.validate,
      event_object_id: site.name,
      site: site.name,
    })

    SiteStore.actions.setSite(site)
    navigation.push('/acceptcgu')
  }

  if (loading) {
    return <Loader />
  }

  return (
    <CenterContainer>
      <Header role="banner">
        <Logo src={require('assets/logo.png').default} alt="" />
        <HintLabel>{i18n.t('components.siteChoice.selectCampus')}</HintLabel>
      </Header>

      <TitleHelmet title={i18n.t('screens.selectSite.title')} />

      <Main>
        <CenterContainer>
          {sites && sites.length > 0 && (
            <SiteList>
              {sites.map((s) => (
                <li
                  key={s.id}
                  onClick={() => onValidate(s)}
                  aria-label={i18n.t(
                    `accessibility.ariaLabels.selectSite.site${defaultSiteCode === s.code ? 'Selected' : ''}`,
                    { site: s.name }
                  )}>
                  <SiteItem selected={defaultSiteCode === s.code}>
                    {!!s.image ? (
                      <SiteImage src={s.image} alt="" />
                    ) : (
                      <SiteImage src={require('assets/building_default.jpeg').default} />
                    )}
                    <SiteTitle>{s.name}</SiteTitle>
                  </SiteItem>
                </li>
              ))}
            </SiteList>
          )}
        </CenterContainer>
      </Main>
    </CenterContainer>
  )
}

export default SitePicker

const CenterContainer = styled('div')`
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`

const Header = styled('header')`
  display: flex;
  flex-direction: column;
`

const HintLabel = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  margin: 40px 0 30px;
`

const Logo = styled('img')`
  margin-top: 40px;
  object-fit: contain;
  align-self: center;
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
`

const SiteList = styled('ul')`
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(2, ${SITE_OUTER_WIDTH}px);
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: ${720}px) {
    grid-template-columns: repeat(1, auto);
  }

  @media only screen and (min-width: ${breakpoints.mediumBig}px) {
    grid-template-columns: repeat(3, ${SITE_OUTER_WIDTH}px);
  }
`

const SiteImage = styled('img')`
  width: ${IMG_WIDTH}px;
  height: ${IMG_HEIGHT}px;
  border-radius: 12px;
  object-fit: cover;
  transition: box-shadow 0.1s ease-in-out;
`

const SiteItem = styled('div')<{ selected: boolean }>`
  flex-direction: column;
  margin: 20px ${HORIZONTAL_GAP}px;
  width: ${IMG_WIDTH}px;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(1.02);
  }

  :hover ${SiteImage} {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }
`

const SiteTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 14px 0 0;
`
