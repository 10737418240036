import ws from 'utils/Webservice'

const api = {
  surveysList: (siteId: number, category?: string) => {
    const categorySuffix = !!category ? `?category=${category}` : ''

    return ws<SurveysWS>(`/${siteId}/surveys${categorySuffix}`, 'GET')
  },

  answerSurvey: (siteId: number, surveyId: string, body: SurveyAnswersWS) =>
    ws<void>(`/${siteId}/surveys/${surveyId}/answer`, 'POST', body),
  getAllMyTeams: () => ws<TeamsList>(`/challenges/teams`, 'GET'),
}

export default api
