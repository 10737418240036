import * as React from 'react'
import styled from 'theme/styled-components'

import NewsItem from './NewsItem'
import TagFilter from './TagFilter'

import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Modal from 'components/modal/Modal'
import EmptyLabel from 'components/label/EmptyLabel'
import SectionsList, { Section } from 'components/list/SectionsList'
import { MIN_WIDTH } from 'components/list/ListItemRework'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as NewsStore from './store'
import * as SiteStore from 'site/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import IconButton from 'components/icons/IconButton'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

// Largeur à partir de laquelle on force l'affichage sur 3 colonnes
// Sinon on risque d'avoir une grille de plus de 3 colonnes à cause de "auto-fit"
const WIDTH_TRESHOLD = 1270

const FILTERS = ['all', 'info', 'event']

const TAGS = [
  'MOBILITY',
  'SECURITY',
  'ENVIRONMENT',
  'DIVERSITIES',
  'WELLBEING',
  'CAMPUS',
  'CIVICENGAGEMENT',
  'BIODIVERSITY',
  'RSE',
  'INTERNATIONAL',
  'URGENT',
  'NEWS',
  'LOCAL',
  'FUTURE',
  'SOCIETY',
  'BRANDS',
  'MEDICALSOCIAL',
  'CORPORATE',
  'FUNDATION',
  'WORKQUALITY',
  'SERVICE',
  'HERITAGE',
  'ARCHITECTURAL_PROJECT',
]

const NewsScreen = () => {
  const i18n = useI18n()

  const news = useReducer(NewsStore.store, (s) => s.news)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const SECTIONS: Section[] = FILTERS.map((filter) => ({
    label: i18n.t(`screens.news.filter.${filter}`),
    id: filter,
  }))

  const [selectedSection, setSelectedSection] = React.useState(SECTIONS[0])
  const [tagFilters, setTagFilters] = React.useState<string[]>(TAGS)
  const [loading, setLoading] = React.useState(false)

  const displayedItems = React.useMemo(
    () =>
      news
        .filter((n) => selectedSection.id === 'all' || n.type === selectedSection.id)
        .filter((n) => tagFilters.includes(n.tag)),
    [news, selectedSection, tagFilters]
  )

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.news,
      screen_name:
        values.screens[
          selectedSection.id === 'all' ? 'news' : selectedSection.id === 'event' ? 'newsEvents' : 'newsArticles'
        ],
    })
  }, [selectedSection])

  React.useEffect(() => {
    setLoading(true)

    api
      .all(site.id)
      .then((l) => NewsStore.actions.setNews(l.articles))
      .catch(NewsStore.actions.resetNews)
      .finally(() => setLoading(false))
  }, [site])

  if (loading) {
    return <Loader />
  }

  const onTagsSubmit = (selected: string[]) => {
    setTagFilters(selected)
    Modal.close()
  }

  const renderItem = (article: ArticleV4) => (
    <li key={article.id}>
      <NewsItem article={article} />
    </li>
  )

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.news.title')} />

        <Header>
          <SectionsList sections={SECTIONS} selectedSection={selectedSection} onSectionClick={setSelectedSection} />

          <IconContainer>
            <IconButton
              name="options"
              onClick={() =>
                Modal.open({
                  Content: () => (
                    <TagFilter tags={TAGS} selected={tagFilters} onSubmit={onTagsSubmit} onCancel={Modal.close} />
                  ),
                })
              }
              ariaLabel={i18n.t('accessibility.ariaLabels.news.openModalFilterNews')}
              size={40}
              isFloating
            />
          </IconContainer>
        </Header>

        {displayedItems.length === 0 ? (
          <EmptyLabel label={i18n.t('screens.news.empty')} />
        ) : (
          <ListContainer>
            <StyledList>{displayedItems.map(renderItem)}</StyledList>
          </ListContainer>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default NewsScreen

const ScreenContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const Header = styled('div')`
  padding-top: 52px;
  display: flex;
  flex-direction: row;
  align-self: center;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.background};

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px 25px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px 25px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px 25px;
  }
`

const IconContainer = styled('div')`
  margin-left: 24px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    position: fixed;
    margin: 0;
    bottom: 50px;
    right: 80px;
    bottom: 30px;
    right: 30px;
  }
`

const ListContainer = styled('div')`
  flex: 1;
  padding: 40px 124px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 35px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 35px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 35px 35px;
  }
`

const StyledList = styled('ul')`
  display: grid;
  list-style: none;
  gap: 65px;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(${MIN_WIDTH}px, 1fr));

  @media only screen and (min-width: ${WIDTH_TRESHOLD}px) {
    grid-template-columns: repeat(3, minmax(${MIN_WIDTH}px, 1fr));
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
`
