import * as React from 'react'
import styled from 'theme/styled-components'

import Main from 'components/main/Main'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import SurveyItem from './SurveyItem'
import EmptyLabel from 'components/label/EmptyLabel'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import * as SurveyStore from 'survey/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'
import { filterSurveys } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

import { MAX_WIDTH } from './SurveyDetailScreen'

const renderItem = (item: SurveyWS) => <SurveyItem key={item.id} item={item} />

const SurveyScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const surveys = useReducer(SurveyStore.store, (s) => s.surveys)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')

  React.useEffect(() => {
    analytics.screen({ screen_name: values.screens.surveys })
  }, [])

  React.useEffect(() => {
    if (!!site) {
      api
        .surveysList(site.id)
        .then((res) => {
          SurveyStore.actions.setSurveys(filterSurveys(res.surveys))
          setStatus('ok')
        })
        .catch(() => setStatus('error'))
    }
  }, [site, i18n.lang])

  if (status === 'loading') {
    return <Loader />
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.survey.title')} />

        <ContentContainer>
          <Title>{i18n.t('screens.survey.availableSurveys')}</Title>
          {status === 'error' || surveys.length === 0 ? (
            <EmptyLabel
              label={status === 'error' ? i18n.t('common.errorDescription') : i18n.t('screens.survey.noSurvey')}
              icon="survey"
            />
          ) : (
            <SurveysList>{surveys.map(renderItem)}</SurveysList>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default SurveyScreen

const ScreenContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 90px 180px 20px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 90px 100px 20px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 40px 70px 20px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 40px 35px 20px;
  }
`

const ContentContainer = styled('div')`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
`

const Title = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  font-size: 20px;
`

const SurveysList = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`
