import * as React from 'react'
import styled from 'styled-components'
import { PotentialVisitor } from './types'

import { onSubmitUtils } from './utils'

import useI18n from 'i18n/useI18n'
import { setHours, addMinutes, addHours, isBefore, setMinutes, addDays } from 'date-fns'
import { notWeekend } from 'meeting/booking/utils'
import { useFormik } from 'formik'
import { breakpoints } from 'utils/breakpoints'

import api from './api'

import * as GuestStore from './store'
import useReducer from 'store/useReducer'

import GuestForm from './GuestForm'
import VisitorPicker from './VisitorPicker'
import CompanionPicker from './CompanionPicker'
import Loader from 'components/status/Loader'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Drawer from 'components/drawer/Drawer'
import MyVisits from './MyVisits'

import analytics from 'utils/analytics'
import firebaseValues from 'firebaseanalytics/firebaseValues.json'

export interface Values {
  start: Date
  end: Date
  subject: string
  visitors: PotentialVisitor[]
  companion?: Companion
  isSelfSelected: boolean
}

export type SetFieldValueType = ReturnType<typeof useFormik>['setFieldValue']
export type HandleChangeType = ReturnType<typeof useFormik>['handleChange']

const DEFAULT_END_HOUR = 18

const GET_END_HOUR_VISIT = (startDateTime: Date) => {
  const endDate =
    startDateTime.getHours() >= DEFAULT_END_HOUR
      ? addHours(startDateTime, 1)
      : setMinutes(setHours(new Date(), DEFAULT_END_HOUR), 0)
  return isBefore(endDate, startDateTime) ? addDays(endDate, 1) : endDate
}

type Status = 'loaded' | 'loading' | 'error'

const MINUTES_TO_ADD_START_DATE = 15
const INITIAL_START_DATE = notWeekend(addMinutes(new Date(), MINUTES_TO_ADD_START_DATE))

const initialValues: Values = {
  start: INITIAL_START_DATE,
  end: GET_END_HOUR_VISIT(INITIAL_START_DATE),
  subject: '',
  visitors: [],
  companion: undefined,
  isSelfSelected: true,
}

const GuestScreen = () => {
  const i18n = useI18n()

  const userDetails = useReducer(GuestStore.store, (s) => s.userDetail)

  const [status, setStatus] = React.useState<Status>('loading')
  const [shouldUpdateMyVisits, setShouldUpdateMyVisits] = React.useState(false)

  React.useEffect(() => {
    analytics.screen({ screen_name: firebaseValues.screens.guest })

    api
      .getUserDetail()
      .then((res) => {
        GuestStore.actions.setUserDetails(res)
        setStatus('loaded')
      })
      .catch(() => setStatus('error'))
  }, [])

  const onSuccessFormFunction = () => {
    if (!!values.companion) {
      api
        .createVisit({
          meetingName: values.subject,
          startDateTime: values.start.toISOString(),
          endDateTime: values.end.toISOString(),
          visitors: values.visitors,
          companion: values.companion,
        })
        .then(() => {
          analytics.event({
            event_feature: firebaseValues.eventName.guest,
            event_action: firebaseValues.actions.createVisit,
          })

          Alert.open({
            title: i18n.t('screens.guest.success', { count: values.visitors.length }),
            description: i18n.t('screens.guest.descAlertVisitSucces', {
              mailSend: i18n.t('screens.guest.mailSendAfterSuccessVisit'),
              seeMyVisits: i18n.t('screens.guest.visitSavedInMyVisits'),
            }),
          })
          // This state used to update the list of visits after a new one is added
          // Every time the value of the boolean will change, a function will be triggered
          // in the drawer to refresh the list of visits
          setShouldUpdateMyVisits(shouldUpdateMyVisits ? false : true)
          resetValues()
        })
        .catch(() => Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('errors.retry') }))
    }
  }

  const onSubmit = (values: Values) => onSubmitUtils(values, i18n, onSuccessFormFunction, true)

  const { values, setFieldValue, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    onSubmit,
  })

  const resetValues = () => {
    resetForm()
    setFieldValue(
      'companion',
      !!userDetails && !!userDetails.isAccompanying
        ? {
            id: userDetails.id,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
          }
        : undefined
    )

    const newStart = notWeekend(addMinutes(new Date(), MINUTES_TO_ADD_START_DATE))
    setFieldValue('start', newStart)
    setFieldValue('end', GET_END_HOUR_VISIT(newStart))
  }

  const pageTitle = i18n.t('screens.guest.title')

  return (
    <ScreenContainer>
      <Main>
        <>
          <TitleHelmet title={pageTitle} />
          <Breadcrumb>{pageTitle}</Breadcrumb>

          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !userDetails || !userDetails.hasBadge ? (
            <ErrorMessage>{i18n.t('screens.guest.noBadge')}</ErrorMessage>
          ) : (
            <GuestRegistrationContainer>
              <Title>{i18n.t('screens.guest.title')}</Title>
              <IntroAvailability>{i18n.t('screens.guest.introAvailability')}</IntroAvailability>

              <Content>
                <LeftContainer>
                  <GuestForm values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
                  <VisitorPicker values={values} setFieldValue={setFieldValue} />
                </LeftContainer>

                <RightContainer>
                  <CompanionPicker
                    companion={values.companion}
                    isSelfSelected={values.isSelfSelected}
                    setFieldValue={setFieldValue}
                    userDetails={userDetails}
                  />
                </RightContainer>
              </Content>

              <ButtonContainer>
                <Button label={i18n.t('common.validate')} onClick={handleSubmit} />
              </ButtonContainer>
            </GuestRegistrationContainer>
          )}
        </>
      </Main>
      <Drawer icon="agenda">
        <MyVisits shouldUpdateMyVisits={shouldUpdateMyVisits} />
      </Drawer>
    </ScreenContainer>
  )
}

// CONTAINERS

const ScreenContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const GuestRegistrationContainer = styled('div')`
  padding: 50px 0px 0px 200px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    padding: 30px 0px 0px 0px;
    align-items: center;
  }
`
const Content = styled('div')`
  flex-direction: row;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`
const LeftContainer = styled('div')`
  flex-direction: column;
`
const RightContainer = styled('div')`
  flex-direction: column;
  margin-left: 135px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin: 25px 0px 0px 0px;
  }
`
const ButtonContainer = styled('div')`
  width: 327px;
  margin-top: 50px;
  margin-left: 265px;
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin-left: 0px;
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px 0px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin-bottom: 40px;
  }
`

const IntroAvailability = styled('h2')`
  ${(props) => props.theme.fonts.h3};
  margin: 10px 0px 30px;
  text-decoration: underline;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.h3};
  margin: 50px;
`
const Breadcrumb = styled('span')`
  ${(props) => props.theme.fonts.body};
  padding: 50px 0px 0px 135px;
  color: ${(props) => props.theme.colors.darkGrey};
`

export default GuestScreen
