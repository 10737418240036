import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Icon from 'components/icons/Icon'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  opened: boolean
}

const WIDTH = 232

const SwitchSiteButton = ({ opened }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const dropdownRef = React.useRef<HTMLUListElement>(null)

  const site = useReducer(SiteStore.store, (s) => s.site)
  const sites = useReducer(SiteStore.store, (s) => s.sites)

  const [openList, setOpenList] = React.useState(false)

  const siteCity = React.useMemo(() => {
    if (!!site && !!site.address) {
      return site.address.split(' ').at(-1)
    }
  }, [site])

  React.useEffect(() => {
    if (!opened) {
      setOpenList(false)
    }
  }, [opened])

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        // On ferme la liste des sites si le clic est fait ailleurs que dans la liste
        // On rajoute un timeout pour éviter que ça rentre en conflit avec la ligne
        // onClick={() => setOpenList((open) => !open)} du Container qui permet d'ouvrir/fermer
        // la liste selon l'état précédent
        setTimeout(() => setOpenList(false), 200)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const renderItem = (item: SiteV4) => (
    <Item
      key={item.id}
      onClick={() => {
        analytics.event({
          event_feature: values.eventName.changeSite,
          event_action: values.actions.validate,
          event_object_id: item.name,
        })
        SiteStore.actions.setSite(item)
        setOpenList(false)
      }}
      aria-label={i18n.t('accessibility.ariaLabels.switchSite.linkChangeSite', { newSite: item.name })}>
      <ItemImage src={item.image} />
      <ItemContent>
        <ItemLabel>{item.name}</ItemLabel>
        {item.id === site?.id && <Icon name="check" size={16} color={Theme.colors.functionalities.news} />}
      </ItemContent>
    </Item>
  )

  if (!site) {
    return null
  }

  return (
    <MainContainer>
      <Container
        opened={opened}
        image={site?.image}
        onClick={() => setOpenList((open) => !open)}
        tabIndex={0}
        aria-label={i18n.t('accessibility.ariaLabels.drawer.switchSite')}>
        <DarkOverlay opened={opened} />

        <InfosContainer opened={opened}>
          <SiteInfos>
            <SiteName>{site.name}</SiteName>
            {!!siteCity && <SimpleLabel>{`(${siteCity})`}</SimpleLabel>}
          </SiteInfos>

          <SwitchContainer>
            <SimpleLabel>{i18n.t('common.change')}</SimpleLabel>
            <Icon name="switch" size={16} color={Theme.colors.white} cursor="pointer" />
          </SwitchContainer>
        </InfosContainer>
      </Container>

      {opened && openList && <List ref={dropdownRef}>{sites.map(renderItem)}</List>}
    </MainContainer>
  )
}

export default SwitchSiteButton

const MainContainer = styled('div')`
  position: relative;
  align-self: center;
`

// BUTTON

const DarkOverlay = styled('div')<{ opened: boolean }>`
  position: absolute;
  height: ${(props) => (props.opened ? 84 : 48)}px;
  width: ${(props) => (props.opened ? WIDTH : 58)}px;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 12px;
  transition: opacity 0.25s ease-in-out;
  background-color: ${(props) => props.theme.colors.primaryDark};
`

const Container = styled('button')<{ opened: boolean; image?: string }>`
  height: ${(props) => (props.opened ? 84 : 48)}px;
  width: ${(props) => (props.opened ? WIDTH : 58)}px;
  margin: ${(props) => (props.opened ? 0 : 18)}px 0;
  padding: 10px;
  overflow: hidden;
  z-index: 1;
  transition: all 0.25s ease-in-out;

  border-width: 0;
  border-radius: 12px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%),
    url(${(props) => props.image}) lightgray 50% / cover no-repeat;

  cursor: pointer;

  :hover ${DarkOverlay} {
    opacity: 0.2;
  }
`

const InfosContainer = styled('div')<{ opened: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  transition-delay: ${(props) => (props.opened ? 0.15 : 0)}s;
`

const SiteInfos = styled('div')`
  display: block;
`

const SiteName = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.white};
  max-width: 130px;
  margin: 0;
  text-align: left;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 1);
`

const SwitchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const SimpleLabel = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.white};
  margin: 0;
  text-align: left;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 1);
`

// OPTIONS LIST

const List = styled('ul')`
  position: absolute;
  top: 92px;
  left: 0px;
  z-index: 5;
  width: ${WIDTH}px;
  margin: 0;
  padding: 8px 0;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid rgba(157, 156, 169, 0.5);
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
`

const Item = styled('li')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  padding: 4px 12px;
  gap: 12px;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.colors.contentBackground};
  }
`

const ItemImage = styled('img')`
  object-fit: cover;
  height: 32px;
  width: 32px;
  border-radius: 2px;
`

const ItemContent = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ItemLabel = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`
