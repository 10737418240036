import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import Drawer from 'components/drawer/Drawer'
import MyBookingList from './MyBookingList'
import SearchForm from './SearchForm'
import Loader from 'components/status/Loader'
import ModalLoader from 'components/status/ModalLoader'
import { SimpleAnimation } from 'components/animation/Animator'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as BookingStore from './store'
import * as ReferentielStore from 'store/referentiel/referentiel'

import { breakpoints } from 'utils/breakpoints'
import { notWeekend, userIsResident } from './utils'

import api from './api'

import { setHours, startOfDay } from 'date-fns'

import analytics from 'utils/analytics'
import firebaseValues from 'firebaseanalytics/firebaseValues.json'

export interface Values {
  start: Date
  end: Date
  range: boolean
  period: 'morning' | 'afternoon' | 'allDay'
}

const TODAY = startOfDay(notWeekend(new Date()))

export const initialValues: Values = {
  start: setHours(TODAY, 9),
  end: setHours(TODAY, 13),
  range: false,
  period: 'morning',
}

const BookingScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SiteStore.store, (s) => s.site!)
  const user = useReducer(UserStore.store, (s) => s.user!)

  const [searching, setSearching] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [form, setForm] = React.useState<Values>(initialValues)
  const [data, setData] = React.useState<RoomPlanningSearchResult[]>([])

  React.useEffect(() => {
    analytics.screen({
      screen_feature: firebaseValues.screens.booking,
      screen_name: firebaseValues.screens.bookingRoom,
    })

    // get referentiels on start
    api.referentiels(site.id).then((res) => ReferentielStore.actions.setReferentiels(res.referentiels))
  }, [])

  React.useEffect(() => {
    setSearching(true)

    api
      .search(site.id, {
        availabilityStartDate: form.start.toISOString(),
        availabilityEndDate: form.end.toISOString(),
      })
      .then((res) => setData(res.meetings))
      .finally(() => setSearching(false))
  }, [form])

  React.useEffect(() => {
    if (loading) {
      ModalLoader.open()
    } else {
      ModalLoader.close()
    }
  }, [loading])

  const onSubmit = (values: Values) => {
    analytics.event({ event_feature: firebaseValues.eventName.booking, event_action: firebaseValues.actions.search })
    setForm(values)
    return Promise.resolve()
  }

  const onBook = (room: PlanningSearchResult) => {
    const name = (room.data && room.data[`libelle_${i18n.lang}`]) || room.email
    Alert.open({
      title: i18n.t('screens.meeting.booking.alert.book'),
      description: i18n.t('screens.meeting.booking.alert.bookMessage', {
        room: name,
        start: form.start,
        end: form.end,
      }),
      buttons: [
        {
          label: i18n.t('common.yes'),
          onClick: () => {
            Alert.close()
            setLoading(true)

            analytics.event({
              event_feature: firebaseValues.eventName.booking,
              event_action: firebaseValues.actions.validateBooking,
              event_object_id: room.spaceId,
            })

            api.deskBooking
              .createReservation({
                start: form.start.toISOString(),
                end: form.end.toISOString(),
                address: room.email!,
              })
              .then(() =>
                Alert.open({
                  title: i18n.t('screens.meeting.booking.alert.success'),
                  description: i18n.t('screens.meeting.booking.alert.successMessage', { room: name, date: form.start }),
                })
              )
              .catch(() =>
                Alert.open({
                  title: i18n.t('common.error'),
                  description: i18n.t('screens.meeting.booking.alert.errorBookingMessage'),
                })
              )
              .finally(() => {
                setLoading(false)

                // get meetings after book
                api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
              })
          },
        },
        {
          label: i18n.t('common.no'),
          onClick: Alert.close,
        },
      ],
    })
  }

  const isResident = user.officeLocation ? userIsResident(user.officeLocation, site.code) : false
  const datas = React.useMemo(() => (isResident ? data : data.filter((d) => d.isVisitor)), [isResident, data])

  const buildings = React.useMemo(() => Array.from(new Set(datas.map((d) => d.building).filter(Boolean) as string[])), [
    datas,
  ])

  const renderBuilding = (building: string, group: RoomPlanningSearchResult[]) => {
    const items = group.filter((d) => d.building === building)

    if (items.length === 0) {
      return null
    }

    const floors = Array.from(new Set(items.map((d) => d.floor).filter(Boolean) as string[])).sort((a, b) =>
      a === 'RDC' ? -1 : Number(a) < Number(b) ? -1 : 0
    )

    const residentItems = items.filter((d) => !d.isVisitor)
    const visitorItems = items.filter((d) => d.isVisitor)

    return (
      <StyledListItem key={building}>
        <BuildingContainer>
          <BuildingHeader>{building}</BuildingHeader>
          {residentItems.length > 0 && (
            <>
              <StatusHeader>{i18n.t('screens.meeting.booking.villages.resident')}</StatusHeader>
              <StyledList>{floors.map((f) => renderFloor(f, residentItems))}</StyledList>
            </>
          )}
          {visitorItems.length > 0 && (
            <>
              <StatusHeader>{i18n.t('screens.meeting.booking.villages.visitor')}</StatusHeader>
              <StyledList>{floors.map((f) => renderFloor(f, visitorItems))}</StyledList>
            </>
          )}
        </BuildingContainer>
      </StyledListItem>
    )
  }

  const renderFloor = (floor: string, group: RoomPlanningSearchResult[]) => {
    const items = group.filter((d) => d.floor === floor)

    if (items.length === 0) {
      return null
    }

    const villages = Array.from(
      new Set(items.map((d) => d.village || 'other').filter(Boolean) as string[])
    ).sort((a, b) => (a === 'other' ? 1 : a.localeCompare(b)))

    return villages.map((v) => renderVillage(v, floor, items))
  }

  const renderVillage = (village: string, floor: string, group: RoomPlanningSearchResult[]) => {
    const items = group.filter((d) => (d.village || 'other') === village)

    if (items.length === 0) {
      return null
    }

    const floorTitle = i18n.t('screens.meeting.booking.floor', { floor })
    const villageTitle =
      village === 'other'
        ? i18n.t('screens.meeting.booking.villages.otherVillages')
        : i18n.t('screens.meeting.booking.village', { village })

    return (
      <StyledListItem key={`${floor}_${village}`}>
        <FloorContainer>
          <FloorHeader>{`${floorTitle} - ${villageTitle}`}</FloorHeader>
          <FloorRooms>
            <StyledList> {items.map(renderRoom)} </StyledList>
          </FloorRooms>
        </FloorContainer>
      </StyledListItem>
    )
  }

  const renderRoom = (room: PlanningSearchResult) => (
    <StyledListItem key={room.spaceId} isRoom>
      <RoomContainer>
        <RoomLabel>{(room.data && room.data[`libelle_${i18n.lang}`]) || room.email}</RoomLabel>
        <Button label={i18n.t('screens.meeting.booking.book')} onClick={() => onBook(room)} style="small" />
      </RoomContainer>
    </StyledListItem>
  )

  return (
    <ScreenContainer>
      <TitleHelmet
        title={i18n.t(
          `screens.meeting.booking.title${site && site.alternativeFunctionalityDesign ? '_altHeader' : ''}`
        )}
      />
      <Main>
        <>
          <SearchForm onFormSubmit={onSubmit} initialValues={initialValues} />

          <ResultContainer>
            {searching ? (
              <CenterContainer>
                <Loader />
              </CenterContainer>
            ) : (
              <>
                {datas.length > 0 ? (
                  <SimpleAnimation>
                    <Container>
                      <StyledList> {buildings.map((b) => renderBuilding(b, datas))} </StyledList>
                    </Container>
                  </SimpleAnimation>
                ) : (
                  <NoResultTitle>{i18n.t('screens.meeting.booking.noResult')}</NoResultTitle>
                )}
              </>
            )}
          </ResultContainer>
        </>
      </Main>

      <Drawer
        icon="roombooking"
        onToggle={(isOpen) =>
          isOpen &&
          analytics.screen({
            screen_feature: firebaseValues.screens.booking,
            screen_name: firebaseValues.screens.bookingReservations,
          })
        }>
        <MyBookingList form={form} />
      </Drawer>
    </ScreenContainer>
  )
}

export default BookingScreen

const ScreenContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.contentBackground};
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const CenterContainer = styled('div')`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const ResultContainer = styled('div')`
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const NoResultTitle = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  flex: 1;
  margin: 0px 0px 20px;
`

const Container = styled('div')`
  flex-direction: column;
`

const BuildingContainer = styled('div')`
  margin-top: 25px;
  flex-direction: column;
`

const BuildingHeader = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px;
`

const StatusHeader = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.secondary};
  margin: 15px 0px 0px;
`

const FloorContainer = styled('div')`
  margin-top: 5px;
`

const FloorHeader = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px;
`

const FloorRooms = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 900px;
  margin-top: 10px;
`

const RoomContainer = styled('div')`
  width: 350px;
  margin-right: 30px;
  margin-bottom: 15px;
  padding: 25px 14px;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
`

const RoomLabel = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  flex: 1;
  margin: 0px 20px 0px 0px;
`

const StyledList = styled('ul')`
  list-style: none;
  padding: 0;
`
const StyledListItem = styled('li')<{ isRoom?: boolean }>`
  ${(props) => !!props.isRoom && 'display: flex'};
`
