import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'

import { isAfter } from 'date-fns'

import useI18n from 'i18n/useI18n'

import { CONTENT_WIDTH, groupeSlotsByDay } from './utils'
import { capitalize } from 'utils/stringUtils'

interface Props {
  place: string
  allSlots: Slot[]
  booked: Booking[]
  onSlotSelect: (slot?: Slot) => void
  onDaySelect: (day: string) => void
  canBook: boolean
}

const SlotsList = ({ place, allSlots, booked, onSlotSelect, onDaySelect, canBook }: Props) => {
  const i18n = useI18n()

  const [selectedDay, setSelectedDay] = React.useState<string>()

  const groupedSlots = React.useMemo(() => {
    if (!!allSlots) {
      return groupeSlotsByDay(allSlots)
    }
    return []
  }, [allSlots])

  const slotsDays = React.useMemo(() => (!!groupedSlots ? Object.keys(groupedSlots) : []), [groupedSlots])

  const slots = React.useMemo(() => {
    if (allSlots) {
      const filteredSlots = !!selectedDay ? allSlots.filter((slot) => slot.startDate.includes(selectedDay)) : allSlots
      return filteredSlots.sort((a, b) => a.startDate.localeCompare(b.startDate))
    }
    return []
  }, [allSlots, selectedDay])

  const multipleDays = React.useMemo(() => slotsDays.length > 1, [slotsDays])

  const selectDay = (day: string) => {
    setSelectedDay(day)
    onDaySelect(day)
  }

  React.useEffect(() => {
    if (!!slotsDays && slotsDays.length > 0) {
      selectDay(slotsDays[0])
    }
  }, [slotsDays])

  const renderDay = (item: string) => {
    const date = new Date(item)
    const isSelected = item === selectedDay

    return (
      <li key={item}>
        <DayContainer selected={isSelected} onClick={() => selectDay(item)}>
          <DayNumber>{i18n.t('screens.register.detail.dayNumber', { date })}</DayNumber>
          <DayName>{capitalize(i18n.t('screens.register.detail.monthString', { date }).slice(0, 3) + '.')}</DayName>
          <DayName>{capitalize(i18n.t('screens.register.detail.dayString', { date }).slice(0, 3) + '.')}</DayName>
        </DayContainer>
      </li>
    )
  }

  const renderSlot = (item: Slot) => {
    const numberUser = item.numberUser || 0
    const limitUser = item.limitUser || 0
    const isAvailable = numberUser < limitUser
    const isAfterMaxDate = isAfter(new Date(), new Date(item.endDateInscription))
    const isAlreadyBooked = booked.some((booked) => booked.slotId === item.id)
    const isBookable = isAvailable && !isAlreadyBooked && !isAfterMaxDate

    return (
      <SlotContainer
        key={item.id}
        isAlreadyBooked={isAlreadyBooked}
        isBookable={isBookable && canBook}
        onClick={() => {
          if (isBookable && canBook) {
            onSlotSelect(item)
          }
        }}>
        <SlotInfo>
          <SlotTime>
            {i18n.t('screens.home.feature.timeSlot', {
              start: new Date(item.startDate),
              end: new Date(item.endDate),
            })}
          </SlotTime>

          <Line>
            <SlotOccupancy colored isAvailable={isAvailable && !isAfterMaxDate}>
              {!isAvailable
                ? i18n.t('screens.register.detail.full')
                : isAfterMaxDate
                ? i18n.t('screens.register.detail.dateMaxReached')
                : i18n.t('screens.register.detail.available', { count: limitUser - numberUser })}
            </SlotOccupancy>
            {isBookable && !!place && <SlotOccupancy>{` • ${place}`}</SlotOccupancy>}
          </Line>

          {isBookable && (
            <SlotOccupancy>
              {i18n.t('screens.register.detail.deadline', { date: new Date(item.endDateInscription) })}
            </SlotOccupancy>
          )}
        </SlotInfo>

        <ButtonContainer>
          <Button label={i18n.t('screens.register.detail.book')} onClick={() => null} width={90} />
        </ButtonContainer>
      </SlotContainer>
    )
  }

  return (
    <div>
      {multipleDays && <DaysList>{slotsDays.map(renderDay)}</DaysList>}
      <List>{slots.map(renderSlot)}</List>
    </div>
  )
}

export default SlotsList

// DAYS LIST

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const DaysList = styled('ul')`
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 16px 24px 8px 0px;

  // Scrollbar non visible mais scroll autorisé :
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; // Internet Explorer, Edge
  scrollbar-width: 0px; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`

const DayContainer = styled('button')<{ selected: boolean }>`
  flex-direction: column;
  width: 62px;
  height: 62px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.primaryDark : props.theme.colors.lightGrey)};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.colors.contentBackground : props.theme.colors.white)};
  transition: all 0.25s ease-in-out;
  :hover {
    box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  }
`

const DayNumber = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
`

const DayName = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

// SLOTS LIST

const List = styled('ul')`
  list-style: none;
  margin: 0px;
  padding: 0px 0px 40px;
  max-width: ${CONTENT_WIDTH}px;
`

const ButtonContainer = styled('div')`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`

const SlotContainer = styled('div')<{ isAlreadyBooked?: boolean; isBookable?: boolean }>`
  max-width: ${CONTENT_WIDTH - 48}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.isAlreadyBooked ? props.theme.colors.contentBackground : props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  padding: 20px 16px;
  box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  margin-top: 16px;
  transition: all 0.25s ease-in-out;
  ${(props) =>
    props.isBookable &&
    `cursor: pointer;
    :hover {
      box-shadow: 0px 5px 6px 4px rgba(161, 161, 161, 0.25);
    }
    :hover ${ButtonContainer} {
      opacity: 1;
    }`};
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

const SlotOccupancy = styled(Text)<{ colored?: boolean; isAvailable?: boolean }>`
  ${(props) => (props.colored ? props.theme.fonts.subtitleBold : props.theme.fonts.subtitle)};
  color: ${(props) => props.colored && (props.isAvailable ? props.theme.colors.available : props.theme.colors.full)};
  text-decoration: none;
  margin-top: 4px;
`

const Line = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 4px;
`
