import { createEvent, createStore } from 'effector'
import * as DirectoryStore from 'directory365/store'

const resetOnNewUser = () => {
  // Les stores à reset seulement si l'utilisateur connecté est différent du précédent
  DirectoryStore.actions.resetFav()
}

export interface UserSelf {
  id: string
  displayName: string | null
  mail: string | null
  surname: string | null
  givenName: string | null
  photoUrl?: string
  jobTitle: string | null
  companyName: string | null
  officeLocation: string | null
  department: string | null
  userType: string | null
  businessPhones: string[]
  mobilePhone: string | null
}

interface UserState {
  user?: UserSelf
  lastuser?: string
  sessionId?: string
  tokenVisioglobe?: VisioglobeToken
}

export const actions = {
  setUser: createEvent<UserSelf>('setUser'),
  setTokenVisioglobe: createEvent<VisioglobeToken>('setTokenVisioglobe'),
  resetUser: createEvent('resetUser'),
  setSessionId: createEvent<string | undefined>('setSessionId'),
  reset: createEvent('reset'),
}

const initialState: UserState = {
  user: undefined,
  lastuser: undefined,
  sessionId: undefined,
  tokenVisioglobe: undefined,
}

export const store = createStore<UserState>(initialState, { name: 'user' })
  .on(actions.setUser, (s, user) => {
    if (s.lastuser !== user.id) {
      resetOnNewUser()
    }
    return { ...(s.lastuser === user.id ? s : initialState), user, lastuser: user.id }
  })
  .on(actions.setTokenVisioglobe, (s, tokenVisioglobe) => ({ ...s, tokenVisioglobe }))
  .on(actions.resetUser, (s) => ({ ...s, user: undefined }))
  .on(actions.setSessionId, (s, sessionId) => ({ ...s, sessionId }))
  .on(actions.reset, () => ({ ...initialState }))
