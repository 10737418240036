import * as React from 'react'

import useResize from 'utils/useResize'
import { MapBuilding, MovePosition, PoiMap } from './types'
import { VGWindow, Route, RouteNavigation, EventActionCallback, Mapviewer } from './visioweb'
import Logger from 'utils/Logger'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

import { HEADER_HEIGHT } from 'components/main/Main'

const BASE_URL = 'https://mapserver-auth.visioglobe.com/'

const OPTIMIZATIONS = {
  batchTexts: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: false,
  },
  batchIcons: {
    enabled: true,
    padding: 2,
    atlasSize: 1024,
    delayCanvasCreation: true,
  },
  unloadDelay: 2000,
}

export const PMR_EXCLUDE = ['stairway', 'escalator', 'stair']
export const FREE = ['free', 'unbooked-free']

const useMap = (hash: string, lang: 'en' | 'fr', token?: string, initialPlace?: string) => {
  const MapViewer = React.useMemo(() => /* TODO MBA */ {
    const ret = new (((window as unknown) as VGWindow).visioweb as any).Mapviewer() as Mapviewer
    ;(window as any).MAPVIEWER = ret
    return ret
  }, [])
  const ref = React.useRef<HTMLDivElement>(null)

  const [height, width] = useResize()

  const [status, setStatus] = React.useState('init')
  const [navigating, setNavigating] = React.useState(false)
  const [building, setBuilding] = React.useState<string>()
  const [floor, setFloor] = React.useState<string>()
  const [place, setPlace] = React.useState<string>()

  const [buildings, setBuildings] = React.useState<MapBuilding[]>([])
  const [route, setRoute] = React.useState<Route>()
  const [nav, setNav] = React.useState<RouteNavigation>()

  /**
   * callback called when map is fully loaded and venue is ready
   */
  const onVenueLoaded = () => {
    const datas =
      MapViewer.getExtraData().resources[lang]?.localized.locale[lang] ||
      MapViewer.getExtraData().resources.default.localized.locale.default
    const bs = MapViewer.multiBuildingView.getVenueLayout().buildings

    const next: MapBuilding[] = bs.map((b) => ({
      id: b.id,
      name: datas.venueLayout[b.id] ? datas.venueLayout[b.id].name : b.id,
      floors: b.floors.map((f) => ({
        id: f.id,
        name: datas.venueLayout[f.id] ? datas.venueLayout[f.id].name : f.id,
      })),
    }))

    // get all buildings for venue
    setBuildings(next)
  }

  /**
   * callback called when map change building or floor
   * @param move the moving object from visioglobe
   */
  const onMove: EventActionCallback = (move) => {
    if (move.type === 'exploreStateWillChange') {
      setBuilding((move.args.target as MovePosition).buildingID)
      setFloor((move.args.target as MovePosition).floorID)
    }
  }

  /**
   * action to move to building / floor / place
   * @param building building to move
   * @param floor floor to move (required for place, optional for building)
   * @param place place to move (optional for building or floor)
   */
  const goTo = (building: string, floor?: string, place?: string) => {
    setPlace(place)
    setFloor(floor)
    setBuilding(building)
  }

  /**
   * update map colors for occupancies + check for free
   * @param occupancies the occupancy map
   */
  const setAvailable = (occupancies: OccupancyList) => {
    Object.values(occupancies).forEach((occ) => {
      const free = occ.status && FREE.includes(occ.status)
      const icon = MapViewer.getPlaceIcon(occ.visioglobeId)
      if (free) {
        if (!icon) {
          MapViewer.setPlaceIcon(occ.visioglobeId, { url: '/map/free.png', zoomScaleFactor: 0, width: 0, scale: 0.5 })
        }
      } else {
        if (icon) {
          MapViewer.setPlaceIcon(occ.visioglobeId, undefined)
        }
      }

      if (occ.color) {
        MapViewer.setPlaceColor(occ.visioglobeId, occ.color)
      }
    })
  }

  /**
   * update pois on map based on referentials
   * @param pois mapping id -> name
   */
  const updatePOIs = (pois: PoiMap) => {
    Object.keys(pois).forEach((id) => {
      const pl = MapViewer.getPlace(id)

      if (pl) {
        pl.setName(pois[id])
      }
      // MapViewer.setPlaceName(id, pois[id])
    })
  }

  /**
   * start route between 2 places
   * @param from starting place
   * @param to ending place
   * @param pmr should use PMR or not ?
   * @param lng translation for instructions
   */
  const computeRoute = (from: string, to: string, pmr: boolean, lng: 'fr' | 'en') => {
    // calculate route information
    return MapViewer.computeRoute({
      src: from,
      dst: to,
      language: lng,
      computeNavigation: true,
      routingParameters: {
        requestType: 'fastest',
        excludedAttributes: pmr ? PMR_EXCLUDE : [],
      },
    }).then((res) => {
      // create route
      const r = /* TODO MBA */ new (((window as unknown) as VGWindow).visioweb as any).Route(
        MapViewer,
        res.data
      ) as Route

      // route invalid
      if (!r.isValid() || res.data.status !== 200) {
        throw new Error('route invalid')
      }

      // navigate to start
      setBuilding('')

      if (r.initialFloor) {
        MapViewer.multiBuildingView.goTo({ floorID: r.initialFloor, place: from })
      } else {
        MapViewer.multiBuildingView.goTo({ place: from })
      }

      // and show route
      r.show()

      // store route
      setRoute(r)
      setNav(r.navigation)

      // translate route navigation steps
      MapViewer.navigationTranslator.translateInstructions(res.data.navigation, lng)

      return res
    })
  }

  /**
   * navigate to next step
   */
  const nextRoute = () => {
    if (nav) {
      nav.displayNextInstruction()
    }
  }

  /**
   * navigate to previous step
   */
  const previousRoute = () => {
    if (nav) {
      nav.displayPrevInstruction()
    }
  }

  /**
   * stop route and reset navigation
   */
  const stopRoute = () => {
    if (route) {
      route.remove()
    }

    setNav(undefined)
    setRoute(undefined)
  }

  /**
   * reset place selection
   */
  const resetSelection = () => {
    setPlace(undefined)
  }

  /**
   * Callback called when place is selected
   * @param ev the event
   * @param el the visioglobe element selected
   */
  const onClick = (ev: any, el: any) => {
    if (el.vg && el.vg.id) {
      analytics.event({
        event_feature: values.eventName.map,
        event_action: values.actions.clickFromMap,
        event_object_id: el.vg.id,
      })
      setPlace(el.vg.id)
    }
  }

  /**
   * Callback called when main map system is initialized
   */
  const onInit = () => {
    // start render and give dimensions
    MapViewer.start()
    MapViewer.resize(height - HEADER_HEIGHT, width)

    // set the map as multi building and multi floors
    MapViewer.setupMultiBuildingView({
      viewType: 'multibuilding',
      animationType: 'translation',
      container: ref.current!,
    })

    // set the translator
    MapViewer.setupNavigationTranslator(MapViewer.getPlaces())

    // set camera params
    MapViewer.camera.minRadius = 50.0
    MapViewer.cameraDrivenExplorer.maxExploreDistance = 300.0
    MapViewer.cameraDrivenExplorer.setEnabled(true)

    // add listener on building/floor changed
    MapViewer.on('exploreStateWillChange', onMove)
    // start at global location
    MapViewer.multiBuildingView.goTo({ mode: 'global', noViewpoint: true })

    // venue loaded we try to find informations
    onVenueLoaded()

    // update status at initialized
    setStatus('initialized')
  }

  React.useEffect(() => {
    // add listener on initialization
    MapViewer.on('initializeCompleted', onInit)

    // download map and setup render
    MapViewer.load({
      path: `${BASE_URL}${hash}/descriptor.json`,
      cameraType: 'perspective',
      onObjectMouseUp: onClick,
      optimizations: OPTIMIZATIONS,
      token,
    }).then(() => {
      if (ref.current) {
        MapViewer.setupView(ref.current)
      }
    })
  }, [])

  React.useEffect(() => {
    if (status === 'initialized' && initialPlace) {
      const pl = MapViewer.getPlace(initialPlace)

      if (pl) {
        const building = buildings.find((b) => b.floors.find((f) => f.id === pl.vg.floor))

        if (building) {
          setBuilding(building.id)
          setFloor(pl.vg.floor)
          setPlace(initialPlace)
        }
      }
    }
  }, [status])

  React.useEffect(() => {
    if (status === 'initialized') {
      // resize the map when container size changed
      MapViewer.resize(width, height - HEADER_HEIGHT)
    }
  }, [height, width, status])

  React.useEffect(() => {
    if (buildings.length === 1) {
      setBuilding(buildings[0].id)
    }
  }, [buildings])

  React.useEffect(() => {
    setNavigating(true)
    if (place) {
      MapViewer.multiBuildingView.goTo({ place }).finally(() => setNavigating(false))
    } else if (floor) {
      MapViewer.multiBuildingView.goTo({ mode: 'floor', floorID: floor }).finally(() => setNavigating(false))
    } else if (building) {
      MapViewer.multiBuildingView.goTo({ mode: 'global', buildingID: building }).finally(() => setNavigating(false))
    }
  }, [building, floor, place])

  React.useEffect(() => {
    if (place && !navigating) {
      const pl = MapViewer.getPlace(place)

      if (pl) {
        try {
          const POI = MapViewer.addPOI({
            position: { ...pl.vg.position, z: (pl.vg.position.z || 0) + 0.5 },
            url: '/map/pin.png',
            scale: { x: 2, y: 2 },
          })

          return () => {
            if (POI) {
              POI.remove()
            }
          }
        } catch (err) {
          Logger.error('Unable to add POI', err)
        }
      }
    }
  }, [place, navigating])

  const actions = {
    goTo,
    setAvailable,
    computeRoute,
    previousRoute,
    nextRoute,
    stopRoute,
    resetSelection,
    updatePOIs,
  }

  return [ref, status, building, floor, place, buildings, nav, actions] as const
}

export default useMap
