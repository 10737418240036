import config from 'core/src/config'
import * as userStore from 'store/user/user'

const PLATFORM = 'web'

export const blobToB64 = (data: Blob): Promise<string> =>
  new Promise((res, rej) => {
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onloadend = function () {
      const base64data = reader.result
      res(base64data as string)
    }
  })

export const manageSessionId = (userId?: string) => {
  if (!userId) {
    // Réinitialisation du session id
    userStore.actions.setSessionId(undefined)
  } else if (!userStore.store.getState().sessionId) {
    // Enregistrement d'un session id (qui sera utilisé pour les events Firebase)
    const sessionId = `Session_${userId}_${PLATFORM}_${config.VERSION}_${new Date().toISOString()}`
    userStore.actions.setSessionId(sessionId)
  }
}
