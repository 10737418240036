import firebase from 'firebase/app'
import 'firebase/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

import config from 'core/src/config'

import * as UserStore from 'store/user/user'
import * as SiteStore from 'site/store'
import * as i18nStore from 'i18n/i18n'

export const INTERN_LABEL = 'Interne'

export type ScreenContext = 'push' | 'shortcut' | 'homeContent' | 'menu' | 'link'

interface ContextTags {
  site?: string
  user_identifier?: string
  user_type?: typeof INTERN_LABEL
  session_identifier?: string
}

interface ScreenTags extends ContextTags {
  screen_feature?: string // fonctionnalité globale
  screen_name: string // écran affiché
  screen_object_id?: string // id de l'objet affiché (article, restaurant, salle...)
  screen_context?: ScreenContext // comment on est arrivé sur l'écran (raccourci, lien...)
}

interface EventTags extends ContextTags {
  event_feature: string // fonctionnalité globale
  event_action: string // description de l'action
  event_object_id?: string // id de l'objet concerné par l'action (article, restaurant, salle...)
}

const getMainContextTags = (tags: ScreenTags | EventTags) => {
  const site = tags.site || SiteStore.store.getState().site?.name
  const user_identifier = tags.user_identifier || UserStore.store.getState().user?.id
  const session_identifier = UserStore.store.getState().sessionId

  if (!!site && !!user_identifier && !!session_identifier) {
    return { site, user_identifier, user_type: INTERN_LABEL, session_identifier } as ContextTags
  }
  return {}
}

const getScreenContextTags = (tags: ScreenTags) => ({
  app_version: config.VERSION,
  language: i18nStore.store.getState().lang,
  screen_context: tags.screen_context,
})

export default {
  screen: (screenTags: ScreenTags) => {
    firebase.analytics().logEvent(values.eventName.functionnality, {
      screen_feature: screenTags.screen_feature || screenTags.screen_name,
      ...screenTags,
      ...getMainContextTags(screenTags),
      ...getScreenContextTags(screenTags),
    })
  },

  event: (eventTags: EventTags) => {
    firebase.analytics().logEvent(eventTags.event_feature, {
      ...eventTags,
      ...getMainContextTags(eventTags),
    })
  },
}
