import * as React from 'react'
import styled from 'styled-components'
import { DirectoryUser } from './types'

import useReducer from 'store/useReducer'
import * as DirectoryStore from './store'

import useI18n from 'i18n/useI18n'
import { sortUsersByDisplayName, getSections, getSanitizedSection } from './utils'
import sanitize from 'utils/Sanitizer'

import api from './api'

import UserInfoCell from './UserInfoCell'
import UserDetails from './UserDetails'
import Modal from 'components/modal/Modal'
import Loader from 'components/status/Loader'
import SearchBar from 'components/input/SearchBar'

const REGEX_ONLY_LETTERS = new RegExp(/^[a-zA-Z]/)

const MyFavorites = () => {
  const i18n = useI18n()

  const favoritesId = useReducer(DirectoryStore.store, (s) => s.favorites)

  const [favorites, setFavorites] = React.useState<DirectoryUser[]>([])
  const [loading, setLoading] = React.useState(false)
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    setLoading(true)

    api
      .getUsers(favoritesId)
      .then((res) => setFavorites(sortUsersByDisplayName(res.value)))
      .finally(() => setLoading(false))
  }, [favoritesId])

  const checkSanitizeFavUser = (stringToCheck: string) => sanitize(stringToCheck).indexOf(sanitize(search)) >= 0

  const filterFavUsers = (favUser: DirectoryUser) => {
    if (!!favUser.surname && checkSanitizeFavUser(favUser.surname)) {
      return true
    }

    if (!!favUser.givenName && checkSanitizeFavUser(favUser.givenName)) {
      return true
    }

    if (!!favUser.displayName && checkSanitizeFavUser(favUser.displayName)) {
      return true
    }

    if (!!favUser.mail && checkSanitizeFavUser(favUser.mail)) {
      return true
    }

    return false
  }

  const filteredFavorites = React.useMemo(() => favorites.filter(filterFavUsers), [favorites, search])

  const renderLetterSection = (section: string) => {
    const items = filteredFavorites.filter((favUser) => {
      const sanitizedSection = getSanitizedSection(favUser)
      if (!!favUser.displayName && !REGEX_ONLY_LETTERS.test(favUser.displayName) && section === '#') {
        return sanitizedSection
      } else {
        return sanitizedSection.startsWith(section)
      }
    })

    return (
      <li key={section}>
        <React.Fragment>
          <SectionLetter>{section}</SectionLetter>
          <ContactListContainer>{items.map(renderFavoriteContact)}</ContactListContainer>
        </React.Fragment>
      </li>
    )
  }

  const sections = getSections(filteredFavorites)

  const renderFavoriteContact = (contact: DirectoryUser, index: number, list: DirectoryUser[]) => {
    const isLast = list.length === index + 1

    return (
      <li key={contact.id}>
        <React.Fragment>
          <ContactButton
            onClick={() => Modal.open({ Content: () => <UserDetails user={contact} /> })}
            aria-label={!!contact.displayName ? contact.displayName : undefined}>
            <UserInfoCell user={contact} />
          </ContactButton>
          {!isLast && <Separator />}
        </React.Fragment>
      </li>
    )
  }

  return (
    <>
      <DrawerTitle>
        <label htmlFor="idFavouriteDirectorySearch"> {i18n.t('screens.phonebook.myFavorites')}</label>
      </DrawerTitle>
      <SearchBarContainer role="search">
        <SearchBar feature="directory" idSearchInput="idFavouriteDirectorySearch" value={search} onChange={setSearch} />
      </SearchBarContainer>

      {loading ? (
        <Loader />
      ) : sections.length > 0 ? (
        <ListContainer>{sections.map(renderLetterSection)}</ListContainer>
      ) : search.length > 0 ? (
        <MessageText>{i18n.t('screens.phonebook.noResultFavorites')}</MessageText>
      ) : (
        <MessageText>{i18n.t('screens.phonebook.noFavorites')}</MessageText>
      )}
    </>
  )
}

// CONTAINERS

const SearchBarContainer = styled('div')`
  padding: 10px;
`

// TEXTES

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 36px 22px;
`
const SectionLetter = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.iconicGrey};
  text-transform: capitalize;
  padding-left: 19px;
  margin: 0px;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`
const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 0px 22px;
  padding: 10px 0px;
`

// LISTS

const ListContainer = styled('ol')`
  overflow-y: scroll;
  list-style: none;
  padding: 0;
`
const ContactListContainer = styled('ol')`
  list-style: none;
  align-items: center;
  margin-top: 1px;
  padding: 0;
  padding-left: 11px;
`

// BUTTON

const ContactButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 0px;
  text-align: flex-start;
  width: 327px;
  text-align: start;
  margin: 15px 0px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

// AUTRES

const Separator = styled('div')`
  height: 1px;
  width: 327px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

export default MyFavorites
